<template>
  <base-layout>

    <div class="section-header">
      <div class="session-head">
        <div class="type"
             style="overflow: hidden; padding-top: 10px;font-size:0.9rem; line-height: 1rem">
          <span v-if="presentation.code"> {{ presentation.code }} - </span> <span v-html="presentation.title"></span> - <strong
            class="title"
            v-if="persons.length > 0">{{ persons[0].display_name }}
          ({{ persons[0].country }})</strong></div>
        <template v-if="presentation.start_time && presentation.end_time && presentation.start_time  == presentation.start_time && presentation.end_time">
          {{ moment(session.start_time).format('dddd HH:mm') }} - {{ moment(session.end_time).format('HH:mm') }}
        </template>
        <template v-else>
          <div v-if="presentation.start_time && presentation.start_time.split('t')[1]">{{ moment(session.start_time).format('dddd') }} {{ moment(presentation.start_time).format('hh:mm') }} - {{ moment(presentation.end_time).format('hh:mm') }}</div>
        </template>

      </div>
    </div>
    <ion-content id="wcpt-presentation-detail" class="has-header has-subheader has-bottom-banner"
    >
      <div class="presentation-content">
        <div class="left-side">
          <div class="action-buttons">
            <router-link v-if="abstract && abstract.id" class="action-btn" style="text-decoration:none"
               v-show="(abstract.id)"
               :to="abstract ?  '/app/abstracts/'+ abstract.id: ''"><i
                class="icon ion-navicon-round"></i>&nbsp;Show Abstract</router-link>
            <a class="action-btn" style="text-decoration:none"
               v-show="(presentation.slides && presAvailable)"
               :href="presentation.slides"><i class="icon ion-navicon-round"></i>&nbsp;Download Slides</a>
            <FavoriteButton class="action-btn" :fav-id="favId" v-if="presentation.id"></FavoriteButton>
          </div>
          <div class="stream-container" 
               v-if="presentation && (presentation.video_link || presentation.posterlink) && (!isLiveSession || (liveStreamHasEnded))">
            <div class="stream" :style="{'padding-top':isEposter && presentation.posterlink ? '60%' : '56,25%'}">
              <!--TODO: check for session types and show message-->
              <div>
                <!--TODO: replace with real posters and videos-->
                <iframe v-if="presentation.video_link" :src="presentation.video_link"
                        style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
                        allow="autoplay; fullscreen" allowfullscreen></iframe>
                <!--iframe v-if="isEposter && presentation.posterlink" :src="presentation.posterlink"
                        style="position:absolute;top:0;left:0;width:100%;height:100%;border:1px solid grey"
                        frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe-->
              </div>
            </div>
          </div>
          <!--  <div class="session-info" style="color:red; font-weight:bold" v-if="!user"> Please login to view this presentation</div>-->
          <div class="web-person-divider" style="border:none" v-if="persons.length > 0">
            <h3>Speakers</h3>
          </div>
          <person-list :persons="persons" picture="true"></person-list>
          <div class="outline" v-if="presentation.outline" style="margin-top:20px">
            <p v-html="presentation.outline"></p>
          </div>
          <div class="web-person-divider" style="border:none" v-if="sessions.length > 0">
            <h3>Sessions</h3>
          </div>
          <session-list :sessions="sessions" hidelogo="true"></session-list>
          <poll-loader></poll-loader>
        </div>
        <div class="right-side">
          <!--deactivated forum-->
          <forum v-if="1==2"
              class="forum-list"
              :ref-id="id"
              style="margin:5px 2px;width: 100%; display:inline-block; vertical-align: top;overflow: scroll;overflow-x:hidden;margin-top:10px; border-radius:15px"
          ></forum>
        </div>
      </div>

    </ion-content>
  </base-layout>

</template>

<script>
import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import PersonList from "@/components/PersonList";
import SessionList from "@/components/SessionList";
import Forum from "@/components/interactive/Forum";
import FavoriteButton from "@/components/favorite/FavoriteButton";

export default defineComponent({
  name: "PresentationDetail",
  data() {
    return {
      presentation: {},
      persons: [],
      sessions: [],
      absAvailable: false,
      session: {},
      favId:null,
      abstract:[],
      isLiveSession:false,
      sessionHasStarted: false,
      sessionHasEnded: false,
      liveStreamHasStarted: false,
      liveStreamHasEnded: false,
      liveSessionActive: false,
      zoomSessionActive: false,
      actualTime: "",

    };
  },
  components: {
    IonContent,
    "person-list": PersonList,
    "session-list": SessionList,
    forum: Forum,
    FavoriteButton
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
   ...mapActions('googleanalytics',['trackWithLabel']),
    ...mapActions("presentations", [
      "getPresentationById",
      "getPersons",
      "getSessions",
        "getAbstractForPresentation"
    ]),
    ...mapActions("abstracts", [
      "getAbstract",
    ]),
    ...mapActions("sessions", [
      "getSingleSession",
    ]),
     ...mapActions('googleanalytics',['trackWithLabel']),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),

    async getServerTime() {
      try {
        let time_url =
            "https://mantel-api.mobile.documedias.systems/v2/timestamp";
        return (await this.axios.get(time_url)).data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    checkTime(session) {
      let dateNow;
      let sessionStart;
      let sessionEnd;
      let liveSessionStart;
      let liveSessionEnd;
      let zoomSessionEnd;
      dateNow = new Date(moment.utc());//new Date(this.actualTime);
      sessionStart = new Date(session.start_time + "+01:00");
      sessionEnd = new Date(session.end_time + "+01:00");

      this.sessionHasStarted = dateNow > sessionStart;
      this.sessionHasEnded = dateNow > sessionEnd;
      liveSessionStart = moment(sessionStart).subtract(5, "minutes");
      liveSessionEnd = moment(sessionEnd).add(10, "minutes");
      zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
      this.liveStreamHasStarted = dateNow >= liveSessionStart;
      this.liveStreamHasEnded = dateNow >= liveSessionEnd;
      this.liveSessionActive =
          dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
      this.zoomSessionActive =
          dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      this.interval = setInterval(() => {
        this.actualTime = moment(this.actualTime).add(5, "seconds");
        dateNow = moment(this.actualTime);
        sessionStart = new Date(session.start_time + "+01:00");
        sessionEnd = new Date(session.end_time + "+01:00");
        this.sessionHasStarted = dateNow > sessionStart;
        this.sessionHasEnded = dateNow > sessionEnd;

        liveSessionStart = moment(sessionStart).subtract(5, "minutes");
        liveSessionEnd = moment(sessionEnd).add(10, "minutes");
        zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
        this.liveStreamHasStarted = dateNow >= liveSessionStart;
        this.liveStreamHasEnded = dateNow >= liveSessionEnd;
        this.liveSessionActive =
            dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
        this.zoomSessionActive =
            dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      }, 5000);
    },
  },

  async created() {
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(route) {
        if (route.name == 'Presentation Detail') {
          this.moment = moment;
          this.presentation = await this.getPresentationById(route.params.id);

          let trackData = {
            user: this.user,
            item: this.presentation,
            event: 'enter',
            type: 'presentation',
            itemId: this.presentation.id,
          }
          console.log("explota")
          this.trackEvent(trackData)
          this.favId = 'presentations/'+ this.presentation.id;
          this.session = await this.getSingleSession(this.presentation.session_id)
          this.isLiveSession = this.session.location && this.session.location.id != 'da7ad0c0-3ed1-4500-1302-080000000018' ? true : false;
          this.actualTime = await this.getServerTime();
          this.checkTime(this.session);
          this.abstract = await this.getAbstractForPresentation(this.presentation.id) 
          let abs = await this.getAbstract(this.presentation.abstract_id);
          this.absAvailable = !abs ? false : true
          this.persons = await this.getPersons(this.presentation.id);
          this.sessions = await this.getSessions(this.presentation.id);
           this.trackWithLabel(this.presentation.title)
        }else{
          let trackData = {
            user: this.user,
            item: this.presentation,
            event: 'leave',
            type: 'presentation',
            itemId: this.presentation.id,
          }
          this.trackEvent(trackData)
        }
      }
    }
  }
});
</script>
